import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../components/layout"

const AboutPage = () => (
    <Layout>
      <div>
        <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
          <div className="space-y-12">
            <div className="space-y-5 sm:space-y-4 md:max-w-xl lg:max-w-3xl xl:max-w-none">
              <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Our Mission</h2>
              <p className="text-xl text-gray-500">
                We connect riders and coaches to make the sport safer for people and horses.
              </p>
            </div>
          </div>
          <div className="py-12 lg:py-24">
            <div className="space-y-12 lg:grid lg:grid-cols-3 lg:gap-8 lg:space-y-0">
              <div className="space-y-5 sm:space-y-4">
                <h2 className="text-3xl font-extrabold tracking-tight sm:text-4xl">Our Team</h2>
                <p className="text-xl text-gray-500">
                  HorseOfCourse is proudly made in Los Angeles by a crack team of equine technologists.
                </p>
              </div>
              <div className="lg:col-span-2">
                <ul
                  className="space-y-12 sm:divide-y sm:divide-gray-200 sm:space-y-0 sm:-mt-8 lg:gap-x-8 lg:space-y-0"
                >
                  <li className="sm:py-8">
                    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                      <StaticImage
                        src="../images/about-auggie.jpg"
                        quality={80}
                        alt="auggie the horse"
                        className="object-cover shadow-lg rounded-lg"
                      />
                      <div className="sm:col-span-2">
                        <div className="space-y-4">
                          <div className="text-lg leading-6 font-medium space-y-1">
                            <h3>Auggie</h3>
                            <p className="text-cyan-600">Eventing champion / Front-end developer</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="sm:py-8">
                    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                      <StaticImage
                        src="../images/about-bandit.jpg"
                        quality={80}
                        alt="bandit the horse"
                        className="object-cover shadow-lg rounded-lg"
                      />
                      <div className="sm:col-span-2">
                        <div className="space-y-4">
                          <div className="text-lg leading-6 font-medium space-y-1">
                            <h3>Bandit</h3>
                            <p className="text-cyan-600">Retired cross-country mama / Product designer</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                  <li className="sm:py-8">
                    <div className="space-y-4 sm:grid sm:grid-cols-3 sm:items-start sm:gap-6 sm:space-y-0">
                      <StaticImage
                        src="../images/about-ziggy.jpg"
                        quality={80}
                        alt="ziggy the horse"
                        className="object-cover shadow-lg rounded-lg"
                      />
                      <div className="sm:col-span-2">
                        <div className="space-y-4">
                          <div className="text-lg leading-6 font-medium space-y-1">
                            <h3>Ziggy</h3>
                            <p className="text-cyan-600">Tiny tyrant / CEO</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>

                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
)

export default AboutPage
